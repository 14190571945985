<template lang='pug'>
  v-card.deal-tab-card.rounded-card.d-flex.justify-center.align-center(style='width:95%;' flat )
    v-form(v-model="valid" ref='formOpptyDetail')
        v-row(no-gutters).pb-5
            v-col.pr-md-4.mb-3(cols='12' v-for='(item,index) in ainotesField' :key="item.index").my-1
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="bol"' :key='item.index')
                    label.ellipsis.t-black( :title='item.label' ) {{item.label}}
                    v-checkbox#budget_confirmed.ellipsis(style='width:100%' v-model='ainotesData[item.id]' :readonly='!isEdit' dense='dense' :hide-details='true')
                        template(v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="dat"' :key='item.index')
                    label.ellipsis.t-black( :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='!isEdit' v-model='ainotesData[item.id]' solo readonly )
                        template(  v-slot:append)
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='ainotesData[item.id]' solo readonly :rules='item.mandatory && !!isEdit ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable)
                                template(  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                                    v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        v-date-picker(v-model='ainotesData[item.id]' @input='item.menu = false' no-title scrollable)
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="dtt"' :key='item.index')
                    label.ellipsis.t-black( :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='!isEdit' v-model='ainotesData[item.id]' solo readonly )
                        template(  v-slot:append)
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='ainotesData[item.id]' solo readonly :rules='item.mandatory && !!isEdit ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable @click='datepickerFocus(item)')
                                template(  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                                    v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        date-picker(v-model='ainotesData[item.id]' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="str"' :key='item.index' )
                    label.ellipsis.t-black( :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model='ainotesData[item.id]' solo='solo' :readonly='!isEdit' :rules='item.mandatory && !!isEdit ? formRule.fieldRules: []' )
                        template( v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="num"' :key='item.index')
                    label.ellipsis.t-black( :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model.number='ainotesData[item.id]' solo='solo' :readonly='!isEdit' :rules='item.mandatory && !!isEdit ? formRule.numMandatoryRule: []' type='number' @input='ainotesData[item.id] = check_num(ainotesData[item.id])')
                        template( v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="txt"' :key='item.index' )
                    label.ellipsis.t-black( :title='item.label') {{item.label}}
                    v-textarea#customer_needs.textarea-style(v-model='ainotesData[item.id]' solo='solo' :readonly='!isEdit' no-resize)
                        template(v-slot:append )
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(ainotesData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.type=="opt"' :key='item.index')
                    label.ellipsis.t-black(style='width: 119px;' :title='item.label') {{item.label }}
                    v-autocomplete.v-text-field_low_z-index.mt-1(v-model='ainotesData[item.id]'
                            :items="opt_list[item.id]",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='!isEdit'
                            :class=' !isEdit ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            ) 
                        template(v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(ainotesData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import DatePicker from 'vue2-datepicker';

export default {
  props: {  
      oppty_data: {
          type: Object,
          required: true,
      },
      aiNotebookData:{
          type: Object,
          required: true,
      }
  },
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    DatePicker,
  },
  data() {
    return {
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      valid:false,
      isEdit:false,
      loading:false,

      originData:null,
      ainotesData:{},
      formRule: {
        requiredRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contactnNameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
        opptyContactRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
        ],
        nameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
            ],
        contactRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
            ],
        customerBudgetRule: [
            (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
            (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
        ],
        amountRule: [
            (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
        ],
        textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        companyRule: [
            (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
            (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
        ],
      },
      aiNotebookFieldData:[],
      ainotesField:[],
      openDatePicker:null,
      opt_list:{},
    };
  },
  async created() {
    this.aiNotebookFieldData = this.aiNotebookData.field_definition["ai_notes"];
    this.aiNotebookFieldData.forEach((el)=>{
        this.ainotesField.push({label:el.column_name,id:el.column_id,type:el.column_id.slice(0,3),menu:false});
        this.$set(this.ainotesData,el.column_id,null)
        if(el.column_id.slice(0,3)=="opt"){
            this.opt_list[el.column_id] = Object.entries(el.option_set).map((arr) => (
              {
                  key: arr[0],
                  value: arr[1],
              }));
        }
    })

    for(let i of Object.keys(this.oppty_data)){
      this.ainotesData[i] = this.oppty_data[i] == "null" ? null:this.oppty_data[i];
    }
  },
  destroyed() {
  },
  methods: {
    datepickerFocus(item){
        this.openDatePicker = item;
    },
    datepickerChange(value, type){
        if (type === 'minute') {
            this.openDatePicker.menu = false;
        }
    },
    check_num(num){
        if(num === ""){
            return null;
        }
        else{
            return num;
        }
    },
    validate(){
      return this.$refs.formOpptyDetail.validate();
    },
    onEdit() {
        this.isEdit = true;
        this.originData = JSON.parse(JSON.stringify(this.ainotesData));
    },
    cancelEdit() {
        if(this.$refs.formOpptyDetail.validate()){
          this.loading = true;
          this.isEdit = false;
          this.ainotesData = this.originData;
          this.loading = false;
        }
    },
    confirmEdit (){
      if(this.$refs.formOpptyDetail.validate()){
        this.loading = true;
        this.isEdit = false;
        this.loading = false;
        this.$emit("emitUpdateAiNote",this.ainotesData);
      }
      
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog() {
      this.messageDialog = false;
    },
  },
  watch:{
  }
};
</script>v-input--selection-controls__input

<style lang="scss">
.checkbox_height{
  .v-input--selection-controls__input{
    margin-top:-5px;
  }
}
</style>