<template lang='pug'>
  v-card.deal-tab-card.rounded-card.d-flex.justify-center.align-center(v-if='!loading' style='width:95%;' flat )
    v-form(v-model="valid" ref='formOpptyDetail')
        v-row(no-gutters).pb-5
            //- v-col.pr-md-4.mb-3(cols='12' md='6')
            //-     p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width
            //-         label(for='dealName') {{$t('DEAL_DETAIL.NAME')}}
            //-         v-text-field#dealName.ellipsis(v-model='opptyData.name' solo='solo' :readonly='!isEdit' :rules="formRule.nameRules")
            //-             template(v-slot:append )
            //-                 v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
            //-                 v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
            //-                 v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
            //-                 v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
            //- v-col.pr-md-4.mb-3(cols='12' md='6')
            //-     p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width
            //-         label(for='amount') {{$t('DEAL_DETAIL.AMOUNT')}}
            //-         v-text-field(v-model.number='opptyData.amount' solo='solo'  :rules='formRule.amountRule' v-show='isEdit&&amountShow|!amountValidate' :ref="'editAmount'" @blur='showFormatAmount("amount")')
            //-             template(v-slot:append )
            //-                 v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
            //-                 v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
            //-                 v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
            //-                 v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
            //-         v-text-field(v-model='formatAmount' solo='solo' @click='showEditAmount("amount")' @blur='showEditAmount("amount")' :readonly='true' v-show='!amountShow|!amountShow&&amountValidate')
            //-             template(v-slot:append )
            //-                 v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
            //-                 v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
            //-                 v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
            //-                 v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
            v-col.pr-md-4.mb-3(v-for='(item, index) in aiNotebookFieldData' :key="index" cols='12' md='12')
              div(v-if='item.source_type === "original"' v-for='orItem in originField' :key="`1_${orItem}`"  :class='!isEdit ? "remove-arrow" : "" ')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.column_name === orItem && orItem === "expected_close_date"')
                    label.ellipsis.t-black( :title="$t('DEAL_DETAIL.EXPECTED_CLOSE_DATE')" for='expected_close_date') {{$t('DEAL_DETAIL.EXPECTED_CLOSE_DATE')}}
                    v-text-field#expected_close_date(v-if='!isEdit' :rules='formRule.requiredRules' v-model='opptyData.expected_close_date' solo :readonly='!isEdit')
                        template(v-slot:append )
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                    v-menu(v-if='isEdit' ref="menu" v-model="menu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field(v-model='opptyData.expected_close_date' :rules='formRule.requiredRules' solo :readonly='isEdit' v-bind="attrs" v-on="on")
                                template(v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                                    v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        v-date-picker(v-model='opptyData.expected_close_date' @input='menu = false' no-title scrollable)
              div(v-if='item.source_type === "original"' v-for='orItem in originField' :key="`2_${orItem}`"  :class='!isEdit ? "remove-arrow" : "" ')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.column_name === orItem && orItem === "customer_budget"')
                    label.ellipsis.t-black(:title="$t('DEAL_DETAIL.CLIENT_BUDGET')" for='customer_budget') {{$t('DEAL_DETAIL.CLIENT_BUDGET')}}
                    v-text-field(v-model.number='opptyData.customer_budget' solo='solo' :rules='formRule.customerBudgetRule' v-show='isEdit&&budgetShow|!budgetValidate' :ref="'editBudget'" @blur='showFormatAmount("budget")')
                        template(v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary') 
                    v-text-field(v-model='formatBudget' solo='solo' @click='showEditAmount("budget")' @blur='showEditAmount("budget")' :readonly='true' v-show='!budgetShow|!budgetShow&&budgetValidate')
                        template(v-slot:append )
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "original"' v-for='orItem in originField' :key="`3_${orItem}`"  :class='!isEdit ? "remove-arrow" : "" ')
                p.d-flex.align-center.input-has-label.dance.checkbox_height.input-has-label-no-border-just-min-width(v-if='item.column_name === orItem && orItem === "budget_confirmed"')
                    label.ellipsis.t-black(:title="$t('TO_DEAL.BUDGET')" :for='`budget_confirmed`') {{$t('TO_DEAL.BUDGET')}}
                    v-checkbox#budget_confirmed.ellipsis.pl-1(style='width:100%' v-model='opptyData.budget_confirmed' :id='`budget_confirmed`' :readonly='!isEdit' dense='dense' :hide-details='true')
                        template(v-slot:append )
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "original"' v-for='orItem in originField' :key="`4_${orItem}`"  :class='!isEdit ? "remove-arrow" : "" ')
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='item.column_name === orItem && orItem === "customer_needs"')
                    label.ellipsis.t-black(:title="$t('DEAL_DETAIL.CUSTOMER_NEEDS')" for='customer_needs') {{$t('DEAL_DETAIL.CUSTOMER_NEEDS')}}
                    v-textarea#customer_needs.textarea-style(v-model='opptyData.customer_needs' solo='solo' :readonly='!isEdit' no-resize)
                        template(v-slot:append )
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              //- v-col.pr-md-4.mb-3(cols='12')
              //-     p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width
              //-         label(for='description') {{$t('TO_DEAL.DESCRIPTION')}}
              //-         v-textarea#description.textarea-style(v-model='opptyData.description' solo='solo' :readonly='!isEdit' no-resize)
              //-             template(v-slot:append )
              //-                 v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
              //-                 v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
              //-                 v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
              //-                 v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`bol_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="bol" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label' ) {{cfItem.label}}
                    v-checkbox#budget_confirmed.ellipsis.pl-1(style='width:100%' v-model='opptyData[cfItem.id]' :readonly='!isEdit' dense='dense' :hide-details='true')
                        template(v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`dat_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="dat" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='!isEdit' v-model='opptyData[cfItem.id]' solo readonly )
                        template(  v-slot:append)
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="cfItem.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='opptyData[cfItem.id]' solo readonly :rules='cfItem.mandatory && !!isEdit ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable)
                                template(  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                                    v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        v-date-picker(v-model='opptyData[cfItem.id]' @input='cfItem.menu = false' no-title scrollable)
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`dtt_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="dtt" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='!isEdit' v-model='opptyData[cfItem.id]' solo readonly )
                        template(  v-slot:append)
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="cfItem.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='opptyData[cfItem.id]' solo readonly :rules='cfItem.mandatory && !!isEdit ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable @click='datepickerFocus(cfItem)')
                                template(  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                                    v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        date-picker(v-model='opptyData[cfItem.id]' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`str_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="str" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label }}
                    v-text-field.v-text-field_low_z-index(v-model='opptyData[cfItem.id]' solo='solo' :readonly='!isEdit' :rules='cfItem.mandatory && !!isEdit ? formRule.fieldRules: []' )
                        template( v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`num_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="num" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label }}
                    v-text-field.v-text-field_low_z-index(v-model.number='opptyData[cfItem.id]' solo='solo' :readonly='!isEdit' :rules='cfItem.mandatory && !!isEdit ? formRule.numMandatoryRule: []' type='number' @input='opptyData[cfItem.id] = check_num(opptyData[cfItem.id])')
                        template( v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`txt_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="txt" && cfItem.id === item.column_id' 
                :key='cfItem.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label}}
                    v-textarea#customer_needs.textarea-style(v-model='opptyData[cfItem.id]' solo='solo' :readonly='!isEdit' no-resize)
                        template(v-slot:append )
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
              div(v-if='item.source_type === "custom_field"' v-for='cfItem in cfField' :key="`opt_${cfItem.id}`" )
                p.d-flex.align-center.input-has-label.input-has-label-no-border-just-min-width(v-if='cfItem.type=="opt" && cfItem.id === item.column_id' :key='item.index')
                    label.ellipsis.t-black( :title='cfItem.label') {{cfItem.label }}
                    v-autocomplete.v-text-field_low_z-index.mt-1(v-model='opptyData[cfItem.id]'
                            :items="opt_list[cfItem.id]",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='!isEdit'
                            :class=' !isEdit ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            ) 
                        template(v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading' @click='confirmEdit()') icon-check
                            v-icon(size='18' color="red" v-if='isEdit && !loading' @click='cancelEdit()') mdi-window-close  
                            v-icon(size='18' v-if='!isEdit' @click='onEdit(opptyData)') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import CustomFieldDataService from "@/services/CustomFieldDataService";
import DatePicker from 'vue2-datepicker';

export default {
  props: {  
      oppty_data: {
          type: Object,
          required: true,
      },
      aiNotebookData:{
          type: Object,
          required: true,
      }
  },
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    DatePicker,
  },
  data() {
    return {
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      valid:false,
      isEdit:false,
      loading:false,

      originData:null,
      formatAmount: null,     
      amountShow: false,  
      formatBudget: null,
      budgetShow: false,  
      amountValidate: true,
      budgetValidate: true,
      menu:false,
      opptyData:{},
      cf_fields_list:[],
      formRule: {
        requiredRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
        ],
        contactnNameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME_2'),
                (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
        ],
        opptyContactRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
        ],
        nameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_NAME'),
                (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
            ],
        contactRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_OPPTY_Contact'),
                (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
            ],
        customerBudgetRule: [
            (v) => /^\d*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
            (v) => /^.{0,17}$/.test(v) || i18n.t('RULE.RULE_17'),
        ],
        amountRule: [
            (v) => /^\d{1,17}(\.\d{0,2})?$/.test(v) || i18n.t('RULE.RULE_R_NUM_2')
        ],
        textRule: [(v) => (v || '').length <=90 || i18n.t('RULE.RULE_90')],	
        contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
        companyRule: [
            (v) => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
            (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL')
        ],
      },
      aiNotebookFieldData:[],
      originField:[],
      cfField:[],
      openDatePicker:null,
      hasBudget:false,
      opt_list:{},
    };
  },
  async created() {
    this.loading = true;
    this.aiNotebookFieldData = this.aiNotebookData.field_definition["opportunity"];
    this.aiNotebookFieldData.forEach((el)=>{
      if(el.source_type == "custom_field"){
        this.cfField.push({label:el.column_name,id:el.column_id,type:el.column_id.slice(0,3),menu:false});
        this.$set(this.opptyData,el.column_id,null)
      }else if(el.source_type == "original"){
        this.originField.push(el.column_name);
        this.$set(this.opptyData,el.column_name,null)
        if(el.column_name == "customer_budget"){
          this.hasBudget = true;
        }
      }
    })
    for(let i of Object.keys(this.oppty_data)){
      this.opptyData[i] = this.oppty_data[i] == "null" ? null:this.oppty_data[i];
    }
    await this.getCFD();
    await(this.loading = false);
    await(this.showFormatAmount("budget"));
  },
  destroyed() {
  },
  methods: {
    datepickerFocus(item){
        this.openDatePicker = item;
    },
    datepickerChange(value, type){
        if (type === 'minute') {
            this.openDatePicker.menu = false;
        }
    },
    check_num(num){
        if(num === ""){
            return null;
        }
        else{
            return num;
        }
    },
    async getCFD(){
      await CustomFieldDataService.getCustomFieldDetail("Opportunity").then((response)=>{
            let cf_data = response.data[0].definition;
            let cf_data_list = cf_data.filter(el=>el.stage_type_id==this.aiNotebookData.business_process_id || el.stage_type_id == "all");
            cf_data_list.forEach((el)=>{
                for (const [key, value] of Object.entries(el.data)) {
                    if(key.slice(0,3)!="mlt" && key.slice(0,3)!="tst"){
                        if(key.slice(0,3)=="opt"){
                          this.opt_list[key] = Object.entries(value.option_set).map((arr) => (
                                {
                                    key: arr[0],
                                    value: arr[1],
                                }));
                        }
                    }
                }
            })
      })
    },
    validate(){
      return this.$refs.formOpptyDetail.validate();
    },
    onEdit() {
        this.isEdit = true;
        this.originData = JSON.parse(JSON.stringify(this.opptyData));
    },
    cancelEdit() {
        if(this.$refs.formOpptyDetail.validate()){
          this.loading = true;
          // this.showFormatAmount("amount");
          this.showFormatAmount("budget");
          this.isEdit = false;
          this.opptyData = this.originData;
          // this.formatAmount = this.getFormatAmount(this.originData.amount);
          // this.amountValidate = true;
          this.formatBudget = this.getFormatAmount(this.originData.customer_budget);
          this.formatBudget = this.formatBudget.slice(0,-3)
          this.budgetValidate = true;
          this.loading = false;

        }
    },
    confirmEdit (){
      if(this.$refs.formOpptyDetail.validate()){
        this.loading = true;
        // this.showFormatAmount("amount");
        this.showFormatAmount("budget");
        this.isEdit = false;
        // this.formatAmount = this.getFormatAmount(this.opptyData.amount);
        // this.amountValidate = true;
        this.formatBudget = this.getFormatAmount(this.opptyData.customer_budget);
        this.formatBudget = this.formatBudget.slice(0,-3)
        this.budgetValidate = true;
        this.loading = false;
        this.$emit("emitUpdateAiNote",this.opptyData);
      }
      
    },
    showFormatAmount(data){
        if(!this.hasBudget){
          return;
        }
        if(data==='amount'){
        this.amountShow = false;
        this.formatAmount = this.getFormatAmount(this.opptyData.amount);
        this.amountValidate = this.$refs.editAmount.validate();
        if(this.formatAmount == 0.00){
          this.amountValidate = true;
          this.opptyData.amount = 0.00;
        }
        }
        if(data==='budget'){
        this.budgetShow = false;
        this.formatBudget = this.getFormatAmount(this.opptyData.customer_budget).slice(0,-3);
        this.budgetValidate = this.$refs.editBudget[0].validate();
        }
    },
    showEditAmount(data){
        if(this.isEdit && data==='amount')
        {
            this.amountShow = true
            this.$refs.editAmount.focus()
        }   
        if(this.isEdit && data==='budget')
        {
            this.budgetShow = true
            this.$refs.editBudget[0].focus()
        }             
    },
    // Dialog Area ----------------------------------------- [Start]
    
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog() {
      this.messageDialog = false;
    },
  },
  watch:{
  }
};
</script>v-input--selection-controls__input

<style lang="scss">
.checkbox_height{
  .v-input--selection-controls__input{
    margin-top:-5px;
  }
}
</style>